import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Tilt from 'react-parallax-tilt'

const Card = ({ cardData }) => {
  const image = getImage(cardData.background_image.gatsbyImageData)
  return (
    <Tilt
      tiltMaxAngleX={6}
      tiltMaxAngleY={6}
      perspective={900}
      scale={1.05}
      transitionSpeed={2000}
      gyroscope={true}
    >
      <div>
        <Link className="card" to={cardData.link.url}>
          <GatsbyImage image={image} alt={cardData.link_title.text} />
          <div className="card-body">
            <h2 className="card-front">{cardData.link_title.text}</h2>
            <h4 className="card-back">{cardData.link_title.text} </h4>
          </div>
        </Link>
      </div>
    </Tilt>
  )
}

export default Card
